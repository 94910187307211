import React, { Suspense, useContext } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import Package from "../package.json"

import SiteContext from "./contexts/SiteContext"
import Sitemap from "./pages/sitemap"
import RoutePersonalizado from "./routes/Route"

import { CorClara } from "./services/functions"

import { CircularProgress, makeStyles } from "@material-ui/core"

//DESLOGADO
const Estabelecimento = React.lazy(() => import("./pages/Deslogado/estabelecimentos"))
const AvaliacaoPedido = React.lazy(() => import("./pages/AvaliacaoPedido"))

//LOGADO
const Downloads = React.lazy(() => import("./pages/Downloads"))
const ImagensTematicas = React.lazy(() => import("./pages/imagensTematicas"))
const Login = React.lazy(() => import("./pages/Login"))
const Cadastro = React.lazy(() => import("./pages/Cadastro/comum"))
const LoadingDados = React.lazy(() => import("./pages/LoadingDados"))
const Delivery = React.lazy(() => import("./pages/Delivery"))
const Perfil = React.lazy(() => import("./pages/Perfil"))
const ApenasFidelidade = React.lazy(() => import("./pages/ApenasFidelidade"))
const ApenasFidelidadeCartelas = React.lazy(() => import("./pages/ApenasFidelidade/cartelas"))
const ApenasFidelidadeEstabelecimentos = React.lazy(() =>
    import("./pages/ApenasFidelidade/estabelecimentos")
)
const Fidelidade = React.lazy(() => import("./pages/Fidelidade"))
const Produto = React.lazy(() => import("./pages/Delivery/produto"))
const Creditcardweb = React.lazy(() => import("./pages/Creditcardweb"))
const Offline = React.lazy(() => import("./pages/Offline"))
const StatusPedido = React.lazy(() => import("./pages/StatusPedido"))
const ConectarMesa = React.lazy(() => import("./pages/ConectarMesa"))
const HistoricoPedido = React.lazy(() => import("./pages/HistoricoPedido"))
const Promocional = React.lazy(() => import("./pages/Promocional"))

const useStyles = makeStyles({
    fallback_container: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "red",
    },
    fallback_logo: {
        width: "3rem",
        position: "fixed",
        borderRadius: "100%",
    },
})

const SuspenseFallback = () => {
    const { aplicativoDados } = useContext(SiteContext)

    const classes = useStyles()

    return (
        <div className={classes.fallback_container}>
            <img
                className={classes.fallback_logo}
                src={aplicativoDados?.urlLogo ? aplicativoDados?.urlLogo : ""}
            />
            <CircularProgress color="primary" size={80} />
        </div>
    )
}

export default function Routes() {
    const { aplicativoDados, estabelecimentoAtual } = useContext(SiteContext)

    const cor = CorClara(aplicativoDados?.corSitePrimaria) ? "black" : "white"
    const versaoLancamento = "V" + Package.version
    localStorage.setItem("versao", `${versaoLancamento} | `)

    return (
        <div className="global">
            <span id="versao-atual" style={{ color: cor }} onClick={() => window.location.reload()}>
                {versaoLancamento}
            </span>
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                    <Route path="/sitemap.xml" component={Sitemap} />
                    <Route path="/sitemap" component={Sitemap} />
                    <RoutePersonalizado path="/creditcardweb" component={Creditcardweb} />
                    <RoutePersonalizado path="/offline" exact component={Offline} />
                    <RoutePersonalizado
                        dados={true}
                        path="/"
                        exact
                        component={
                            aplicativoDados?.possuiDelivery === false
                                ? Login
                                : estabelecimentoAtual?.id
                                ? Delivery
                                : aplicativoDados?.estabelecimentos?.length > 1
                                ? Estabelecimento
                                : Delivery
                        }
                        titulo={
                            aplicativoDados?.possuiDelivery === false
                                ? "Fazer login"
                                : aplicativoDados?.estabelecimentos?.length > 1
                                ? "Estabelecimentos"
                                : "Cardapio"
                        }
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/downloads/imagensTematicas"
                        component={ImagensTematicas}
                        titulo="Imagens tematicas"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/downloads/:plataforma"
                        component={Downloads}
                        titulo="Downloads Aplicativo e Site"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/downloads"
                        component={Downloads}
                        titulo="Downloads Aplicativo e Site"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/cadastro"
                        component={Cadastro}
                        titulo="Cadastrar"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/login/Acesso"}
                        component={Login}
                        titulo="Fazer login"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/login/pedidoMesa"}
                        component={Login}
                        titulo="Fazer login"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/conectar-mesa"}
                        component={ConectarMesa}
                        titulo="Conectar em mesa"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/mesa"}
                        component={ConectarMesa}
                        titulo="Mesa"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/login"}
                        component={Login}
                        titulo="Fazer login"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/lojas"}
                        component={Estabelecimento}
                        titulo="Lojas"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/delivery/historicoPedidos"
                        component={HistoricoPedido}
                        titulo="Historico de pedidos"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/delivery/pedido/:codigoEstabelecimento/:idPedido/:statusInicial"
                        component={StatusPedido}
                        titulo="Status do pedido"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/delivery/pedido/:idPedido/:statusInicial"
                        component={StatusPedido}
                        titulo="Status do pedido"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/delivery/fidelidade"
                        component={Fidelidade}
                        titulo="Fidelidade"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/delivery/avaliacaoPedido"
                        component={AvaliacaoPedido}
                        titulo="Avaliação de pedido"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/delivery"
                        component={Delivery}
                        titulo="Cardápio"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/fidelidade/cartelas"
                        component={ApenasFidelidadeCartelas}
                        titulo="Cartelas"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/fidelidade/estabelecimentos"
                        component={ApenasFidelidadeEstabelecimentos}
                        titulo="Estabelecimentos"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/fidelidade"
                        component={ApenasFidelidade}
                        titulo="Fidelidade"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/perfil/:param1"
                        component={Perfil}
                        titulo="Fazer login"
                    />
                    <RoutePersonalizado
                        dados={true}
                        privado={true}
                        path="/perfil"
                        component={Perfil}
                        titulo="Perfil"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/estabelecimentos"
                        component={Estabelecimento}
                        titulo="Estabelecimento"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/estabelecimento"
                        component={Estabelecimento}
                        titulo="Estabelecimento"
                    />
                    <RoutePersonalizado
                        dados={true}
                        path="/produto/:idProduto"
                        component={Produto}
                    />
                    <RoutePersonalizado
                        dados={true}
                        path={"/promocional"}
                        component={Promocional}
                        titulo="Promocional"
                    />
                    <RoutePersonalizado path="*">{<Redirect to="/" />}</RoutePersonalizado>
                </Switch>
            </Suspense>
        </div>
    )
}
