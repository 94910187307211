import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import Package from "../../../package.json"
export default function LoadingDados(props) {
    // const android = navigator.userAgent.includes("Android")
    //     return navigator.userAgent.includes("Instagram") && android ? (
    //         <div className="divImageCentroErro">
    //             <Link
    //                 to={{ pathname: window.location.pathname }}
    //                 nibrowser="no"
    //                 target="_blank"
    //                 style={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     marginTop: 200,
    //                     alignItems: "center",
    //                     fontSize: 15,
    //                     top: "2em",
    //                     height: "10vh",
    //                 }}
    //                 size="small"
    //                 className={"botaoVerde"}
    //                 download
    //             >
    //                 Clique para continuar
    //             </Link>
    //             {/*<><img src={setaErro} alt={"erro instagram android"} className="pretoEBranco" style={{"width": "150px","float":"right", "marginRight": "1em", "transform": "rotate(25deg)"}}/>
    //   <h6 style={{"fontWeight": "100", "float": "right"}}>Por favor, clique nos 3 (três) pontinhos e depois em Abrir</h6></> */}
    //         </div>
    //     ) : (
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                flexDirection: "column",
            }}
        >
            <Typography
                gutterBottom
                variant="h6"
                component="h6"
                style={{
                    position: "fixed",
                    top: "2em",
                }}
            >
                Obtendo Informações
            </Typography>

            <div
                style={{
                    background: "#b5b5b5",
                    width: "75px",
                    height: "75px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                }}
            >
                <CircularProgress color="inherit" size={35} />
            </div>
            <Typography gutterBottom variant="h6" component="h6" className="mt-3 px-3 text-center">
                Carregando{" "}
                <span style={{ textTransform: "capitalize" }}>
                    {window.location.hostname
                        .replace("www.", "")
                        .substring(0, window.location.hostname.replace("www.", "").indexOf("."))}
                </span>
            </Typography>
            <Typography gutterBottom variant="paragraph" component="p" className="mt-1">
                Estamos carregando algumas informações
            </Typography>

            <Typography
                gutterBottom
                variant="subtitle1"
                component="p"
                style={{
                    position: "fixed",
                    bottom: "2em",
                }}
            >
                Versão: {Package.version}
            </Typography>
        </div>
    )
}
