import React, { useContext } from "react"
import {
    CadastrarEnderecoCliente,
    ObterCardapioCompletoV1,
    ObterCardapioPedidoMesa,
    ObterCupomDescontoCompleto,
    RegistrarLogErro,
    removerProdutosCategoria,
} from "../services/functions"
import useAlert from "./AlertContext"
import useParametros from "./ParametrosContext"
import SiteContext from "./SiteContext"

const precisaAtualizarCardapio = () => {
    const dataUltimoCardapio = Number(localStorage.getItem("dateCardapioCF"))
    const dataAgora = new Date().getTime()

    return dataUltimoCardapio + 1 * 60 * 1000 < dataAgora
}

export const CalculosContext = React.createContext(null)

export function CalculosContextProvider(props) {
    const { children } = props

    const {
        aplicativoDados,

        estabelecimentoAtual,
        usuarioLogado,
        setUsuarioLogado,

        carrinho,
        setCarrinho,

        cardapio,
        setCardapio,
    } = useContext(SiteContext)

    const parametros = useParametros()

    const alertStart = useAlert()

    const usarCarrinho = () => {
        return JSON.parse(JSON.stringify(carrinho))
    }
    const usarEnderecoAtual = () => {
        // throw new Error("usarEnderecoAtual")
        return JSON.parse(sessionStorage.getItem("enderecoAtual") ?? "{}")
    }
    const usarCardapio = () => {
        return JSON.parse(JSON.stringify(cardapio))
    }
    const usarEstabelecimentoAtual = () => {
        return JSON.parse(JSON.stringify(estabelecimentoAtual))
    }
    const usarUsuarioLogado = () => {
        return JSON.parse(JSON.stringify(usuarioLogado))
    }

    async function calcularValorTotal({
        carrinho = usarCarrinho(),
        enderecoAtual = usarEnderecoAtual(),
        cardapio = usarCardapio(),
        estabelecimentoAtual = usarEstabelecimentoAtual(),
    } = {}) {
        try {
            console.log(">> calcularValorTotal", carrinho, cardapio, enderecoAtual)

            cardapio.cupomDesconto = carrinho.cupomDesconto
            if (cardapio.cupomDesconto) {
                cardapio.percentualDesconto = cardapio.cupomDesconto?.valorDescontoPercentual
                cardapio.valorDesconto = cardapio.cupomDesconto?.valorDesconto
            } else {
                cardapio.percentualDesconto = carrinho.percentualDesconto
                cardapio.valorDesconto = cardapio.valorDesconto
            }

            const isPedidoDeMesa = carrinho.pedido.tipoPedido === 2
            const isRetiradaNoLocal = enderecoAtual.id === -1

            const minimoParaEntregaGratis = Math.min(
                cardapio.minimoEntregaGratis ?? 999999999.9,
                enderecoAtual.minimoEntregaGratis ?? 999999999.9
            )
            carrinho.minimoEntregaGratis = minimoParaEntregaGratis

            let valorProdutosPromocionais = 0
            const valorTotalItens = carrinho.pedido.itens?.reduce((total, item) => {
                if (item.produto.promocional) {
                    valorProdutosPromocionais += item.valorProdutoHistorico
                }

                return total + item.valorProdutoHistorico
            }, 0)

            var valorFinalProdutos = valorTotalItens

            carrinho.percentualDescontoValor = 0.0

            if (cardapio.percentualDesconto && !cardapio.valorDesconto) {
                // DESCONTO PERCENTUAL (Apenas em produtos nao promocionais)
                const descontoPercentual = Math.min(
                    ((valorTotalItens - valorProdutosPromocionais) * cardapio.percentualDesconto) /
                        100,
                    carrinho.cupomDesconto?.maximoDesconto ?? cardapio.maximoDesconto ?? Infinity
                )

                carrinho.percentualDescontoValor = descontoPercentual
                carrinho.pedido.valorDesconto = descontoPercentual

                valorFinalProdutos -= descontoPercentual
            } else {
                //DESCONTO FIXO (Apenas em produtos nao promocionais)
                const descontoFixo = Math.min(
                    Math.max(carrinho.pedido.valorDesconto ?? 0, cardapio.valorDesconto ?? 0),
                    cardapio.maximoDesconto ?? Infinity
                )

                valorFinalProdutos -= descontoFixo
            }

            if (carrinho.pedido?.formaPagamento?.id) {
                // NÃO é calculado sobre taxa de entrega
                carrinho.pedido.descontoFormaPagamento = carrinho.pedido.formaPagamento.desconto
                    ? (valorFinalProdutos * carrinho.pedido.formaPagamento.desconto) / 100
                    : 0

                valorFinalProdutos -= carrinho.pedido.descontoFormaPagamento
            }

            valorFinalProdutos = Math.max(valorFinalProdutos, 0)

            let taxaDeEntrega = 0

            if (enderecoAtual && typeof enderecoAtual.taxaEntrega === "number")
                carrinho.pedido.valorEntrega = enderecoAtual.taxaEntrega

            const isTaxaGratis =
                (minimoParaEntregaGratis && valorFinalProdutos >= minimoParaEntregaGratis) ||
                carrinho.cupomDesconto?.freteGratis

            if (!isRetiradaNoLocal && !isPedidoDeMesa && !isTaxaGratis) {
                // NAO FOR RETIRADA NO LOCAL, PEDIDO MESA NEM TAXA GRÁTIS
                taxaDeEntrega = carrinho.pedido.valorEntrega
                    ? carrinho.pedido.valorEntrega
                    : enderecoAtual.taxaEntrega
                    ? enderecoAtual.taxaEntrega
                    : 0

                taxaDeEntrega = Math.max(taxaDeEntrega, 0)
            }

            carrinho.pedido.valorEntrega = taxaDeEntrega
            carrinho.pedido.taxaEntrega = taxaDeEntrega

            const taxaAdicional = parametros.PUBLIC_TAXA_ADICIONAL
            carrinho.pedido.taxaAdicional = taxaAdicional

            carrinho.pedido.valorTotal =
                valorFinalProdutos + taxaDeEntrega + (taxaAdicional ? Number(taxaAdicional) : 0)

            if (!carrinho.cupomCliente) delete carrinho.cupomCliente

            setCarrinho(carrinho)
            setCardapio(cardapio)
        } catch (error) {
            RegistrarLogErro(error, "calcularValorTotal", {
                carrinho,
                enderecoAtual,
                cardapio,
                estabelecimentoAtual,
            })
            alertStart(
                "Procure os desenvolvedores! Erro calcularValorTotal: " + error.message,
                "error"
            )
            console.error(error)
        }
    }

    const ObterCardapioCalculo = async (props = {}) => {
        const {
            enderecoAtual = usarEnderecoAtual(),
            cardapio = usarCardapio(),
            usuarioLogado = usarUsuarioLogado(),
            estabelecimentoAtual = usarEstabelecimentoAtual(),
            forcarAtualicao = false,
        } = props

        try {
            const deveAtualizar =
                forcarAtualicao ||
                !cardapio?.id ||
                cardapio.idEstabelecimento !== estabelecimentoAtual?.id ||
                precisaAtualizarCardapio()

            if (!deveAtualizar) {
                props.cardapio = cardapio
                retornoVerificarCardapio(props)
                return
            }

            let data = {
                idCliente: usuarioLogado?.cliente?.id,
                idEstabelecimento: estabelecimentoAtual?.id,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }

            // TODO: Precisa?
            if (enderecoAtual?.ativo) {
                data.idEndereco = enderecoAtual?.id
            }

            const mesaLogada = !!JSON.parse(localStorage.getItem("usuarioPedidoMesaCF"))?.logado
            const response = mesaLogada
                ? await ObterCardapioPedidoMesa(data, aplicativoDados)
                : await ObterCardapioCompletoV1(data, aplicativoDados)
            console.log("data ObterCardapio", response)

            if (response.retornoErro) {
                RegistrarLogErro(response, "verificarCardapio - retornoErro", props)
                return
            }

            response.idEstabelecimento = estabelecimentoAtual?.id
            props.cardapio = response
            retornoVerificarCardapio(props)

            return response
        } catch (error) {
            RegistrarLogErro(error, "verificarCardapio", props) //, crypto.randomUUID())
            alertStart(
                "Procure os desenvolvedores! Erro verificarCardapio: " + error.message,
                "error"
            )
        }
    }

    const jsonCupomRequest = ({
        codigo,
        aplicativoDados,
        usuarioLogado,
        carrinho,
        estabelecimentoAtual,
    }) => {
        const data = JSON.parse(JSON.stringify(carrinho.pedido))
        data.estabelecimento = estabelecimentoAtual
        data.cliente = usuarioLogado.cliente
        data.token = `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`
        data.desconto = {
            codigoCupom: codigo,
            appNome: aplicativoDados.appNome,
            mensagem: aplicativoDados.appNome,
            token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
        }
        return data
    }

    const adicionarCupomLink = async props => {
        const { codigo } = props
        const data = jsonCupomRequest(props)
        return await ObterCupomDescontoCompleto(data, aplicativoDados)
    }

    const retornoVerificarCardapio = async props => {
        var {
            enderecoAtual = usarEnderecoAtual(),
            cardapio = usarCardapio(),
            usuarioLogado = usarUsuarioLogado(),
            estabelecimentoAtual = usarEstabelecimentoAtual(),
            carrinho = usarCarrinho(),
        } = props

        try {
            const isRetiradaNoLocal = enderecoAtual?.id === -1

            console.log("retornoVerificarCardapio", cardapio)
            if (!cardapio.codErro > 0) {
                const retorno = cardapio
                let endereco = enderecoAtual
                if (
                    retorno.enderecos != null &&
                    retorno.enderecos.length > 0 &&
                    !isRetiradaNoLocal
                ) {
                    retorno.enderecos.forEach(newEnd => {
                        if (endereco.id == newEnd.id) {
                            endereco = newEnd
                        }
                    })
                }

                if (!carrinho || typeof carrinho != "object") {
                    carrinho = {}
                }

                if (!carrinho?.pedido) {
                    carrinho.pedido = {}
                }

                console.log("enderecoendereco1", endereco)

                if (localStorage.getItem("cupomLink")) {
                    const jsonCupom = JSON.parse(localStorage.getItem("cupomLink"))
                    if (jsonCupom.idCliente === usuarioLogado?.cliente?.id) {
                        props.codigo = jsonCupom.codigo
                        const cupom = await adicionarCupomLink(props)
                        console.log("cupom", cupom)
                        if (!cupom.codErro && !cupom.retornoErro) {
                            window.dispatchEvent(new CustomEvent("adicionarCupom"))
                            cardapio.cupomDesconto = cupom
                            retorno.cupomDesconto = null
                            // retorno.cupomDesconto = cupom
                        } else {
                            localStorage.removeItem("cupomLink")
                        }
                    }
                }

                if (
                    cardapio?.cupomDesconto &&
                    !retorno?.cupomDesconto &&
                    !retorno?.idCartelaCompleta
                ) {
                    retorno.cupomDesconto = cardapio?.cupomDesconto

                    if (retorno.cupomDesconto?.valorDesconto) {
                        retorno.valorDesconto = retorno.cupomDesconto.valorDesconto
                    }

                    if (retorno.cupomDesconto?.valorDescontoPercentual) {
                        retorno.percentualDesconto = retorno.cupomDesconto.valorDescontoPercentual
                    }
                }

                if (cardapio && !cardapio.cupomDesconto && carrinho?.cupomDesconto) {
                    carrinho.cupomDesconto = null
                }

                //INICIO ATUALIZANDO CARRINHO
                carrinho.valorMinimoPedido = retorno.valorMinimoPedido
                carrinho.valorMinimoRetiradaNoLocal = retorno.valorMinimoRetiradaNoLocal
                if (!carrinho.cupomDesconto?.codigoCupom) {
                    carrinho.valorDesconto = retorno.valorDesconto
                    carrinho.percentualDesconto = retorno.percentualDesconto
                }
                carrinho.minimoEntregaGratis = enderecoAtual.minimoEntregaGratis
                    ? Math.min(retorno.minimoEntregaGratis, enderecoAtual.minimoEntregaGratis)
                    : retorno.minimoEntregaGratis
                carrinho.maximoPedidoComDesconto = retorno.maximoPedidoComDesconto
                carrinho.maximoDesconto = retorno.maximoDesconto
                carrinho.cupomDesconto = carrinho.cupomDesconto ?? retorno.cupomDesconto
                const taxa =
                    retorno.enderecos != null && retorno.enderecos.length > 0 && !isRetiradaNoLocal
                        ? endereco.taxaEntrega
                        : null
                carrinho.valorEntrega = taxa
                carrinho.taxaEntrega = taxa
                //pedido
                carrinho.pedido.taxaEntrega = taxa
                carrinho.pedido.valorEntrega = taxa
                if (carrinho.percentualDesconto) {
                    carrinho.pedido.valorDesconto = carrinho.percentualDescontoValor
                } else {
                    carrinho.pedido.valorDesconto = carrinho.valorDesconto
                }
                //FIM ATUALIZANDO CARRINHO

                // console.log('taxataxa', taxa)

                if (retorno?.cupomDesconto?.freteGratis && taxa >= 0) {
                    //console.log('mudando Taxa ')
                    carrinho.pedido.valorEntrega = 0
                    carrinho.pedido.taxaEntrega = 0
                    if (endereco.bairro) {
                        endereco.taxaEntrega = 0
                    }
                    console.log("enderecoendereco2", endereco)
                } else if (retorno?.enderecos?.length && !isRetiradaNoLocal) {
                    carrinho.pedido.valorEntrega = endereco.taxaEntrega
                    carrinho.pedido.taxaEntrega = endereco.taxaEntrega
                    console.log("enderecoendereco3", endereco)
                }

                ////console.log("atualizarCardapio", retorno)
                if (!usuarioLogado?.logado) {
                    retorno.id = "usuarioDeslogado"
                }

                if (aplicativoDados.buscaProdutosPaginada) {
                    removerProdutosCategoria(props)
                }
                props.cardapio = apartirDe(cardapio)

                props.carrinho = carrinho
                props.estabelecimentoAtual = estabelecimentoAtual

                var attCardapio = retorno
                if (estabelecimentoAtual?.adicionarItensNaTelaInicial) {
                    attCardapio = adicionarQuantidadeZeroProdutos(props)
                }

                if (estabelecimentoAtual?.id) {
                    removerProdutosDeOutrosEstabelecimentos(props)
                }

                if (estabelecimentoAtual?.taxaEntrega) {
                    endereco.taxaEntrega = estabelecimentoAtual.taxaEntrega
                }

                // setCardapio(attCardapio)
                props.carrinho = carrinho
                props.enderecoAtual = endereco
                props.cardapio = cardapio

                await calcularValorTotal(props)
            }
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "retornoVerificarCardapio", props)
            alertStart(
                "Procure os desenvolvedores! Erro retornoVerificarCardapio: " + error.message,
                "error"
            )
        }
    }

    const adicionarEndereco = async ({ usuarioLogado, enderecoAtual }) => {
        var resp = null

        try {
            const enderecoBackUp = enderecoAtual
            const data = {
                idCliente: usuarioLogado.cliente.id,
                idEstabelecimento: estabelecimentoAtual ? estabelecimentoAtual.id : null,
                cep: enderecoBackUp.cep,
                uf: enderecoBackUp.uf,
                cidade: enderecoBackUp.cidade,
                bairro: enderecoBackUp.bairro,
                logradouro: enderecoBackUp.logradouro,
                numero: enderecoBackUp.numero,
                complemento: enderecoBackUp.complemento,
                referencia: enderecoBackUp.referencia,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }

            if (enderecoBackUp.bairroEspecifico) {
                data.bairroEspecifico = 1
            } else {
                data.bairroEspecifico = 0
            }

            const response = await CadastrarEnderecoCliente(data, aplicativoDados)

            if (response.retornoErro) {
                //algum erro
                alertStart(response.mensagem, "error")
            } else {
                //tudo certo
                usuarioLogado.cliente.enderecos.push(response)
                setUsuarioLogado(usuarioLogado)
                enderecoAtual.id = response.id
                resp = response.id
            }
        } catch (error) {
            RegistrarLogErro(error, "CadastrarEnderecoCliente", { usuarioLogado, enderecoAtual })
            alertStart(
                "Procure os desenvolvedores! Erro CadastrarEnderecoCliente: " + error.message,
                "error"
            )
        }
        return resp
    }

    const removerProdutosDeOutrosEstabelecimentos = ({
        carrinho = usarCarrinho(),
        estabelecimentoAtual = usarEstabelecimentoAtual(),
    }) => {
        if (carrinho?.pedido?.itens?.length) {
            carrinho.pedido.itens.forEach((item, index) => {
                if (item.idEstabelecimento != estabelecimentoAtual.id) {
                    carrinho.pedido.itens.splice(index, 1)
                }
            })
        }
        return carrinho
    }

    const adicionarQuantidadeZeroProdutos = ({
        cardapio = usarCardapio(),
        carrinho = usarCarrinho(),
    }) => {
        if (carrinho.pedido?.itens?.length > 0) {
            cardapio?.categorias?.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    var temNoCarrinho = false
                    carrinho.pedido.itens.forEach((item, index) => {
                        if (item.produto.id == produto.id) {
                            produto.quantidade = item.quantidade
                            temNoCarrinho = true
                        }
                    })

                    if (temNoCarrinho == false) {
                        produto.quantidade = 0
                    }
                })
            })
        } else {
            cardapio?.categorias?.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    produto.quantidade = 0
                })
            })
        }
        return cardapio
    }

    const apartirDe = cardapio => {
        cardapio?.categorias?.forEach(categoria => {
            categoria.produtos.forEach(produto => {
                const [valorRealCalculado, valorDeCalculado] = calcularValorAPartirDe(produto)

                produto.valorRealCalculado = valorRealCalculado
                produto.valorDeCalculado = valorDeCalculado
            })
        })

        return cardapio
    }

    function calcularValorAPartirDe(produto) {
        try {
            let valorRealCalculado = produto.valor ?? 0
            let valorDeCalculado = produto.valorDe ?? 0

            produto.opcoes.forEach(grupo => {
                // Não é definidor de preço e não tem quantidade mínima, ou seja, o preço mínimo é o próprio preço do produto
                if (grupo.adicional !== 0 && !(grupo.quantidadeMinima > 0)) return

                // Opções ordenadas por ordem crescente de valor, mas informativos vão para o final
                // Anteriormente, utilizava "grupo.opcoes.toSorted", mas alguns browsers não suportam essa função
                const opcoesOrdenadasPorValorCrescente = [...grupo.opcoes].sort(
                    (a, b) =>
                        (a.informativo ? 999 : a.valor ?? 0) - (b.informativo ? 999 : b.valor ?? 0)
                )

                // Considerar maior valor       - Prioridade 1
                const isConsiderarMaiorValor =
                    grupo.opcoes.length > 0 ? grupo.opcoes[0].considerarMaiorValor : false
                // Considerar média dos valores - Prioridade 2
                const isConsiderarMediaValor =
                    !isConsiderarMaiorValor && grupo.opcoes.length > 1
                        ? grupo.opcoes[0].considerarMedia
                        : false

                const quantidadeMinima = Math.min(
                    grupo.quantidadeMinima,
                    opcoesOrdenadasPorValorCrescente.length
                )

                const opcaoMenorValor = opcoesOrdenadasPorValorCrescente[0]
                let valorOpcao

                switch (grupo.adicional) {
                    // 0 - Radio (definidor de valor)
                    case 0:
                        if (opcoesOrdenadasPorValorCrescente[0].valor > 0) {
                            valorRealCalculado = grupo.opcoes[0].valor
                            valorDeCalculado = grupo.opcoes[0].valor
                        }

                        break

                    // 1 - Checkbox
                    case 1:
                        let valor = 0
                        let maiorValor = 0
                        let quantidade = 0
                        for (
                            let i = 0;
                            i <
                            Math.min(
                                grupo.quantidadeMinima,
                                opcoesOrdenadasPorValorCrescente.length
                            );
                            i++
                        ) {
                            const opcao = opcoesOrdenadasPorValorCrescente[i]

                            valorOpcao = opcao.valor ?? 0

                            valor += valorOpcao
                            maiorValor = Math.max(maiorValor, valorOpcao)
                            quantidade++
                        }

                        if (isConsiderarMaiorValor) {
                            valorRealCalculado += maiorValor
                            valorDeCalculado += maiorValor
                        } else if (isConsiderarMediaValor) {
                            valorRealCalculado += valor / quantidade
                            valorDeCalculado += valor / quantidade
                        } else {
                            valorRealCalculado += valor
                            valorDeCalculado += valor
                        }

                        break

                    // 2 - Quantidade (+/-)
                    case 2:
                        valorOpcao = opcaoMenorValor.valor ?? 0

                        valorRealCalculado += valorOpcao * quantidadeMinima
                        valorDeCalculado += valorOpcao * quantidadeMinima

                        break

                    // 3 - Digitável
                    case 3:
                        valorOpcao = opcaoMenorValor.valor ?? 0

                        valorRealCalculado += valorOpcao * quantidadeMinima
                        valorDeCalculado += valorOpcao * quantidadeMinima

                        break

                    default:
                        break
                }
            })

            // Caso haja valor promocional para o dia da semana, valor vem com o valor promocional e valorDe vem com o valor original
            return [
                valorRealCalculado,
                produto.valorDe === null || produto.valorDe === undefined ? null : valorDeCalculado,
            ]
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "calcularValorAPartirDe", data)
            alertStart(
                "Procure os desenvolvedores! Erro calcularValorAPartirDe: " + error.message,
                "error"
            )
        }

        return [produto.valor, null]
    }

    return (
        <CalculosContext.Provider
            value={{
                calcularValorTotal,
                ObterCardapioCalculo,
                adicionarQuantidadeZeroProdutos,
                apartirDe,
                adicionarEndereco,
                usarCarrinho,
            }}
        >
            {children}
        </CalculosContext.Provider>
    )
}
